
import ContactDetailCard from '@/components/crm/contact/ContactDetailCard.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'ContactDetailPage',

  props: ['id'],

  components: { ContactDetailCard }
});
